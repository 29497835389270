import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import LeftImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeLeftSide.svg';
import RightImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeRightSide.svg';

import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWelcomeToCloduraPage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiWelcomeToCloduraPage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <img className="left-bg" src={LeftImg} alt="Left Background" />
      <img className="right-bg" src={RightImg} alt="Right Background" />

      <div className="text">
        <h1>
          Welcome to <span style={{ color: '#03BAED' }}>Clodura.AI,</span>
        </h1>
        <hr className="hr" />
        <h2>Your AI-Powered Sales Engine!</h2>

        <p>
          Your account is successfully created! Your journey to smarter, faster,
          AI-powered B2B lead generation begins now. We're excited to have you
          onboard!
          <br />
          <br />
          Now, get ready to unleash the power of AI and skyrocket your sales
          with Clodura.AI.
        </p>
        <Link to="https://app.clodura.ai/#/auth/login">
          <button>Login</button>
        </Link>
      </div>

      <img
        className="right-img"
        src={
          ZoominfoPageData.allStrapiWelcomeToCloduraPage.nodes[0].img.localFile
            .publicURL
        }
      />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
