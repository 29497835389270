import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import BannerSection from '../containers/Custom/WebsiteEnrichmentThankYouPage/HeaderForSignupSuccess';
import Footer from 'containers/AgencyModern/Footer';
import MainComHeading from '../containers/Custom/WelcomeToClodura/MainComHeading';
import Seo from 'components/seo';
import SignupSuccessDemoVideo from '../containers/Custom/Customers/SignupSuccessDemoVideo';
import Directory from '../containers/Directory';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';
import HeaderForGoogleMicrosoft from '../containers/Custom/WelcomeToClodura/Header';

const Signupsuccess = () => {
  const [provider, setProvider] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const providerParam = queryParams.get('provider');
    if (providerParam) {
      setProvider(providerParam);
    }
  }, []);

  const renderHeader = provider === 'google' || provider === 'microsoft';

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo title="Clodura.AI | Sign up success" />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          {renderHeader ? <HeaderForGoogleMicrosoft /> : <BannerSection />}
          <MainComHeading />
          <SignupSuccessDemoVideo />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Signupsuccess;
