import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import LeftImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeLeftSide.svg';
import RightImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeRightSide.svg';

import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebsiteFormEnrichmentThankYouPage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img =
    ZoominfoPageData.allStrapiWebsiteFormEnrichmentThankYouPage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <img className="left-bg" src={LeftImg} alt="Left Background" />
      <img className="right-bg" src={RightImg} alt="Right Background" />

      <div className="text">
        <h1>
          Please Verify Your
          <span style={{ color: '#03BAED' }}>
            <br /> Email Address
          </span>
        </h1>
        <hr className="hr" />

        <p>Complete sign up through the email we sent to your email address</p>
        <Link to="https://app.clodura.ai/#/auth/verify">
          <button>Verify</button>
        </Link>
      </div>

      <img
        className="right-img"
        src={
          ZoominfoPageData.allStrapiWebsiteFormEnrichmentThankYouPage.nodes[0]
            .img.localFile.publicURL
        }
      />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
